// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {collection, doc, getFirestore} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD8BQaar41QHg7W0wbm62lmQ07ovkDyLNg",
  authDomain: "kovigo-com-ee581.firebaseapp.com",
  projectId: "kovigo-com-ee581",
  storageBucket: "kovigo-com-ee581.appspot.com",
  messagingSenderId: "696164533484",
  appId: "1:696164533484:web:35e5714c29bac52baf11c9",
  measurementId: "G-3GPWMVJPTD",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
