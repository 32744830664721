import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 48px);
  height: calc(100% - 48px);
  padding: 24px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #4ae;
  border-radius: 16px;
  box-shadow: 0px 0px 12px #2468;
`;

export const Gap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ContainerDrawer = styled.div`
  width: 100%;
  padding: 12px;
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const DrawerTitle = styled.p`
  font-size: 20px;
  color: #000;
`;

export const TitleSection = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: #000;
  line-height: 20px;
`;
