import styled from "styled-components";

export const ContainerList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  top: 62px;
  padding: 12px;
`;

export const ContainerTableItem = styled.div<{bgColor: string}>`
  width: 100%;
  padding: 12px;
  height: 100px;
  gap: 8px;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0px 1px 3px ${({bgColor}) => bgColor}80;
  display: grid;
  grid-template-columns: 1fr 190px;
  grid-template-rows: 1fr 1fr;
  background-color: ${({bgColor}) => bgColor};
`;

export const TableItemTitle = styled.p`
  font-size: 20px;
  color: #000;
  font-weight: 500;
`;

export const TableItemStatus = styled.p`
  font-size: 17px;
  justify-self: flex-end;
  color: #000;
  white-space: nowrap;
  font-weight: 500;
`;

export const TableItemPlace = styled.p`
  font-size: 20px;
  color: #494949;
  font-weight: 500;
`;
