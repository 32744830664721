import React from "react";
import {Header} from "../../components/Header";
import * as S from "./styles";
import {TableList} from "../../components/TableList";

export function Home() {
  return (
    <React.Fragment>
      <Header />
      <TableList />
    </React.Fragment>
  );
}
