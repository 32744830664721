import {t} from "i18next";

export function getStatus(status: string) {
  switch (status) {
    case "not-prepared":
      return t("not-prepared");
    case "preparing":
      return t("preparing");
    case "prepared-to-serve":
    case "ready-to-serve":
      return t("prepared-to-serve");
    case "serving":
      return t("serving");
    case "delivered":
      return t("delivered");
    case "payed":
      return t("payed");
    default:
      return t("not-prepared");
  }
}

export function statusColor(status: string) {
  switch (status) {
    case "not-prepared":
    case "preparing":
      return "#fc6f6f";
    case "prepared-to-serve":
    case "ready-to-serve":
    case "serving":
    case "delivered":
      return "#7e7";
    case "payed":
      return "#c9c9c9";
    default:
      return "#fff";
  }
}
