import {Button, TextField, SwipeableDrawer} from "@mui/material";
import * as S from "./styles";
import {
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import {auth} from "../../firebase";
import {useEffect, useRef, useState} from "react";
import React from "react";
import {useNavigate} from "react-router-dom";
import {ReactComponent as KovigoLogo} from "../../assets/kovigoLogo.svg";
import {z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {useForm} from "react-hook-form";
import isMobilePhone from "validator/lib/isMobilePhone";
import {t} from "i18next";
auth.useDeviceLanguage();
const screen: any = {
  recaptchaVerifier: undefined,
  confirmationResult: undefined,
};

export function Login() {
  const schema = z.object({
    number: z.string().refine(isMobilePhone, t("invalidPhoneNumber")),
  });

  const schemaSms = z.object({
    code: z.string().min(6, t("invalidCode")).max(6, t("invalidCode")),
  });

  const {
    register,
    formState: {errors},
    handleSubmit,
  } = useForm<any>({
    resolver: zodResolver(schema),
  });

  const {
    register: registerSms,
    formState: {errors: errorsSms},
    setError: setErrorSms,
    handleSubmit: submitSms,
  } = useForm<any>({
    resolver: zodResolver(schemaSms),
  });

  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const buttonRef = useRef<any>(null);

  function recaptchaVerifierInvisible() {
    function onSignInSubmit() {
      setOpenDrawer(true);
    }

    screen.recaptchaVerifier = new RecaptchaVerifier(auth, buttonRef.current, {
      size: "invisible",
      callback: (response: any) => {
        onSignInSubmit();
      },
    });
  }

  function phoneSignIn({number}: any) {
    const appVerifier = screen.recaptchaVerifier;
    signInWithPhoneNumber(auth, number, appVerifier)
      .then((confirmationResult: ConfirmationResult) => {
        screen.confirmationResult = confirmationResult;
      })
      .catch((error: any) => {
        console.error(error);
      });
  }

  async function sendCode({code}: any) {
    try {
      const userCredential = await screen.confirmationResult.confirm(code);
      localStorage.setItem("userId", userCredential.user.uuid);
      navigate("/");
      setOpenDrawer(false);
    } catch (err) {
      setErrorSms(
        "code",
        {
          message: t("invalidCode"),
        },
        {
          shouldFocus: true,
        }
      );
      console.error(err);
    }
  }

  useEffect(() => {
    recaptchaVerifierInvisible();
  }, []);

  return (
    <React.Fragment>
      <S.Container>
        <KovigoLogo width={250} />
        <S.Gap>
          <S.TitleSection>{t("login")}</S.TitleSection>
          <TextField
            {...register("number")}
            variant="outlined"
            type="tel"
            style={{
              width: "100%",
            }}
            placeholder={t("phone")}
            inputMode="tel"
            error={!!errors?.number?.message}
            helperText={errors.number?.message.toString()}
            FormHelperTextProps={{
              style: {
                margin: 0,
              },
            }}
          />
          <Button
            style={{
              width: "100%",
            }}
            ref={buttonRef}
            onClick={handleSubmit(phoneSignIn)}
          >
            {t("sendSms")}
          </Button>
        </S.Gap>
      </S.Container>
      <SwipeableDrawer
        swipeAreaWidth={0}
        disableSwipeToOpen={true}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          position: "absolute",
          top: 12,
        }}
        anchor="bottom"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => {}}
      >
        <S.ContainerDrawer>
          <S.DrawerTitle>{t("enterSms")}</S.DrawerTitle>
          <TextField
            {...registerSms("code")}
            variant="outlined"
            type="number"
            inputMode="numeric"
            placeholder={t("smsCode")}
            style={{
              marginBottom: 12,
            }}
            error={!!errorsSms?.code?.message}
            helperText={errorsSms.code?.message.toString()}
            FormHelperTextProps={{
              style: {
                margin: 0,
              },
            }}
          />
          <Button onClick={submitSms(sendCode)}>{t("confirmSms")}</Button>
        </S.ContainerDrawer>
      </SwipeableDrawer>
    </React.Fragment>
  );
}
