import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom";
import {AuthRedirect} from "./contexts/AuthRedirect";
import {Login} from "./pages/Login";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import {SnackbarProvider} from "notistack";
import {Home} from "./pages/Home";
import {t} from "i18next";
import {RoleRedirect} from "./contexts/RoleRedirect";
import Roles from "./pages/Roles";
import {AuthProvider} from "./contexts/Auth";
import Companies from "./pages/Companies";
import {CompanyRedirect} from "./contexts/CompanyRedirect";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#757575",
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            padding: "7px 14px 6px 14px !important",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            fontWeight: 700,
            background: "#fff",
            height: 36,
            padding: 0,
            borderRadius: 6,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            background: "#000",
            color: "#fff",
            fontFamily: "Poppins",
            ":hover": {
              background: "#000",
            },
          },
        },
      },
    },
  });

  const NotFound = () => (
    <div>
      <h1>{t("pageNotFound")}</h1>
    </div>
  );

  const router = createBrowserRouter([
    {
      path: "",
      element: <AuthRedirect />,
      children: [
        {
          path: "",
          element: (
            <CompanyRedirect>
              <RoleRedirect>
                <Home />
              </RoleRedirect>
            </CompanyRedirect>
          ),
        },
        {
          path: "roles",
          element: (
            <CompanyRedirect>
              <Roles />
            </CompanyRedirect>
          ),
        },
        {
          path: "companies",
          element: <Companies />,
        },
      ],
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "not-found",
      element: <NotFound />,
    },
    {
      path: "*",
      element: <Navigate to="" replace />,
    },
  ]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
