import {Avatar, Button, IconButton} from "@mui/material";
import * as S from "./styles";
import {Menu} from "@mui/icons-material";
import React, {useState} from "react";
import MenuComponent from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {getAuth} from "firebase/auth";
import {t} from "i18next";
import {useNavigate} from "react-router-dom";
import {DrawerMenu} from "../DrawerMenu";
import {useAuth} from "../../contexts/Auth";

export function Header() {
  const {userData} = useAuth();
  const [openDrawerMenu, setOpenDrawerMenu] = useState(false);

  return (
    <React.Fragment>
      <S.Container>
        <IconButton onClick={() => setOpenDrawerMenu(true)}>
          <Menu
            style={{
              color: "#000",
            }}
          />
        </IconButton>
        <Button
          TouchRippleProps={{
            style: {
              color: "#0008",
            },
          }}
          sx={{
            background: "#0000 !important",
          }}
        >
          <S.DataContainer>
            <S.Title>{userData?.name}</S.Title>
            <Avatar
              src={
                null ||
                "https://th.bing.com/th/id/R.590bcf25d8c4a6f87c3935c1f8d05f27?rik=Cg%2f0M2QvO2VuAQ&pid=ImgRaw&r=0"
              }
            />
          </S.DataContainer>
        </Button>
      </S.Container>
      <DrawerMenu
        open={openDrawerMenu}
        onClose={() => setOpenDrawerMenu(false)}
      />
    </React.Fragment>
  );
}
