import {Button, IconButton, SwipeableDrawer} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as S from "./styles";
import {dataToLocalTime} from "../../utils/time";
import {Check} from "@mui/icons-material";
import {getStatus} from "../../utils/status";
import {t} from "i18next";
import {useEffect} from "react";
import {db} from "../../firebase";
import {doc, getDoc, updateDoc} from "firebase/firestore";
import {useAuth} from "../../contexts/Auth";
import {companyDoc} from "../../repositories/companyRepository";

interface IDrawerTable {
  open: boolean;
  data: any;
  onClose: () => any;
}

export function DrawerTable({open, data, onClose}: IDrawerTable) {
  const {currentRoleData} = useAuth();

  async function cancel() {
    const orderRef = doc(companyDoc(), "orders", data.id);
    await updateDoc(orderRef, {status: currentRoleData.initialStatus});
    onClose();
  }

  async function ready() {
    const orderRef = doc(companyDoc(), "orders", data.id);
    await updateDoc(orderRef, {status: currentRoleData.finalStatus});
    onClose();
  }

  useEffect(() => {
    (async () => {
      if (data?.id && currentRoleData) {
        const orderRef = doc(companyDoc(), "orders", data.id);
        await updateDoc(orderRef, {status: currentRoleData.processingStatus});
      }
    })();
  }, [data, currentRoleData]);

  return (
    <SwipeableDrawer
      swipeAreaWidth={0}
      disableSwipeToOpen={true}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        position: "absolute",
        top: 12,
      }}
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
    >
      <S.DrawerContainer>
        <IconButton
          onClick={onClose}
          sx={{
            width: 30,
            height: 30,
            position: "absolute",
            right: 6,
            top: 6,
          }}
        >
          <CloseIcon />
        </IconButton>
        <S.TitleSection>
          {t("table")} {data?.number} -{" "}
          {getStatus(currentRoleData?.processingStatus)}
        </S.TitleSection>
        <S.DataText>{dataToLocalTime(data?.orderedAt)}</S.DataText>
        <S.ProductsListContainer>
          {data?.products?.map((p: any, i: number) => (
            <S.ProductContainer key={i}>
              <S.ProductTitle>{p.name}</S.ProductTitle>
              <div></div>
              <S.ProductDescription>{p.description}</S.ProductDescription>
              <S.ProductQtdText>
                {t("qty")}: {p.qtd || 0}
              </S.ProductQtdText>
            </S.ProductContainer>
          ))}
        </S.ProductsListContainer>
        <Button
          onClick={ready}
          style={{
            background: "#000",
            color: "#fff",
            marginTop: 12,
            gap: 6,
          }}
        >
          <Check />
          {t("ready")}
        </Button>
        <Button
          onClick={cancel}
          style={{
            background: "#000",
            color: "#fff",
            marginTop: 12,
            gap: 6,
          }}
        >
          <CloseIcon />
          Cancelar
        </Button>
      </S.DrawerContainer>
    </SwipeableDrawer>
  );
}
