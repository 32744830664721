import {Navigate, Outlet} from "react-router-dom";
import {exists} from "../utils/verify";

export const RoleRedirect: React.FC<any> = ({children}) => {
  const role = localStorage.getItem("role");

  if (!exists(role)) {
    return <Navigate to="/roles" replace />;
  }

  return children ? <div>{children}</div> : <Outlet />;
};
