import {ListItemButton, ListItemIcon, SwipeableDrawer} from "@mui/material";
import * as S from "./styles";
import {getAuth} from "firebase/auth";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import {t} from "i18next";
import React from "react";
import {useAuth} from "../../contexts/Auth";
import BusinessIcon from "@mui/icons-material/Business";
import {useNavigate} from "react-router-dom";

interface IDrawerMenu {
  open: boolean;
  onClose: () => any;
}

export function DrawerMenu({open, onClose}: IDrawerMenu) {
  const auth = getAuth();
  const navigate = useNavigate();
  const {setUserData, setCurrentRoleData, setCurrentCompanyData} = useAuth();

  async function signOut() {
    localStorage.removeItem("role");
    localStorage.removeItem("companyId");
    await setCurrentCompanyData(undefined);
    await setCurrentRoleData(undefined);
    await setUserData(undefined);
    auth.signOut();
  }

  function tradeRole() {
    localStorage.removeItem("role");
    navigate("/roles");
  }

  function tradeCompany() {
    localStorage.removeItem("companyId");
    navigate("/companies");
  }

  const list = [
    {
      text: t("signOut"),
      onClick: signOut,
      icon: ExitToAppIcon,
    },
    {
      text: "Trocar Perfil",
      onClick: tradeRole,
      icon: AssignmentIndIcon,
    },
    {
      text: "Trocar Empresa",
      onClick: tradeCompany,
      icon: BusinessIcon,
    },
  ];

  return (
    <SwipeableDrawer
      swipeAreaWidth={0}
      disableSwipeToOpen={true}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        position: "absolute",
        top: 12,
      }}
      anchor="left"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
    >
      <S.DrawerContainer>
        <S.DrawerContainerList>
          {list.map((item, i) => (
            <ListItemButton
              key={i}
              sx={{
                height: "min-content !important",
                flexGrow: "unset",
                color: "#fff",
              }}
              onClick={() => {
                item.onClick?.();
                onClose();
              }}
            >
              <ListItemIcon>
                {React.createElement(item.icon, {
                  style: {
                    color: "#fff",
                  },
                })}
              </ListItemIcon>
              {item.text}
            </ListItemButton>
          ))}
        </S.DrawerContainerList>
        <S.KovigoText>Kovigo</S.KovigoText>
      </S.DrawerContainer>
    </SwipeableDrawer>
  );
}
