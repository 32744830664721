import {Switch} from "@mui/material";
import * as S from "./styles";
import {useEffect, useState} from "react";
import {
  documentId,
  collection,
  getDocs,
  query,
  where,
  onSnapshot,
  QuerySnapshot,
  DocumentData,
} from "firebase/firestore";
import {db} from "../../firebase";
import React from "react";
import {DrawerTable} from "../DrawerTable";
import {getStatus, statusColor} from "../../utils/status";
import {t} from "i18next";
import {useAuth} from "../../contexts/Auth";
import {companyDoc} from "../../repositories/companyRepository";

export function TableList() {
  const [tableList, setTableList] = useState([]);
  const [drawerTableData, setDrawerTableData] = useState<any>();
  const [isDrawerTableOpen, setIsDrawerTableOpen] = useState<boolean>(false);
  const {currentRoleData, role} = useAuth();

  async function getTables() {
    let tablesArr: any[] = [];
    const companyCollection = collection(companyDoc(), "tables");

    await getDocs(companyCollection).then((result) => {
      result.forEach((doc) => {
        const docData = doc.data();
        tablesArr.push({
          ...docData,
          id: doc.id,
        });
      });
    });
    return tablesArr;
  }

  function getOrders(
    result: QuerySnapshot<DocumentData, DocumentData>,
    tables: any
  ) {
    let orders: any = [];
    result.forEach((doc) => {
      const docData = doc.data();

      let resultado: any = [];

      docData.products.forEach((obj: any) => {
        let prod = resultado.find((i: any) => i.id === obj.id);
        if (prod) {
          prod.qtd += obj.qtd;
        } else {
          resultado.push({...obj, obj: obj.qtd});
        }
      });

      orders.push({
        ...docData,
        products:
          role === "dining-room"
            ? resultado
            : resultado.filter((item) => item?.location === role),
        id: doc.id,
      });
    });

    let arr: any = orders
      .filter((item) => item.products.length > 0)
      .map((order: any) => {
        const matchingObj2 = tables.find(
          (table: any) => table.id === order.tableId
        );
        if (matchingObj2) {
          return {
            ...order,
            number: matchingObj2.number,
          };
        }
        return order;
      });

    const currentDate = new Date().getTime();

    setTableList(
      arr.sort((a: any, b: any) => {
        const minusA = currentDate - a.orderedAt.seconds;
        const minusB = currentDate - b.orderedAt.seconds;
        return minusA - minusB;
      })
    );
  }

  useEffect(() => {
    (async () => {
      if (currentRoleData) {
        const qOrders = query(
          collection(companyDoc(), "orders"),
          where("status", "in", [
            currentRoleData.initialStatus,
            currentRoleData.processingStatus,
          ])
        );
        const tables = await getTables();
        await onSnapshot(qOrders, (result) => getOrders(result, tables));
      }
    })();
  }, [currentRoleData]);

  return (
    <React.Fragment>
      <S.ContainerList>
        {tableList.map((table: any, i: number) => (
          <S.ContainerTableItem
            onClick={() => {
              setIsDrawerTableOpen(true);
              setDrawerTableData(table);
            }}
            bgColor={statusColor(table.status)}
            key={i}
          >
            <S.TableItemTitle>
              {t("table")} {table.number}
            </S.TableItemTitle>
            <S.TableItemStatus>{getStatus(table.status)}</S.TableItemStatus>
            <S.TableItemPlace>Bar</S.TableItemPlace>
            <div></div>
          </S.ContainerTableItem>
        ))}
      </S.ContainerList>
      <DrawerTable
        open={isDrawerTableOpen}
        data={drawerTableData}
        onClose={() => setIsDrawerTableOpen(false)}
      />
    </React.Fragment>
  );
}
