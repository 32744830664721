import React, {createContext, useContext, useEffect, useState} from "react";

export interface IRole {
  id: string;
  role: string;
  initialStatus: string;
  processingStatus: string;
  finalStatus: string;
}

export interface ICompany {
  id: string;
  name: string;
}
export interface IAuthProvider {
  userId: string | undefined;
  companyId: string | undefined;
  role: string | undefined;
  userData: {
    uid: string;
    name: string;
    roles: IRole[];
    companies: ICompany[];
  };
  setUserData: React.Dispatch<React.SetStateAction<IAuthProvider["userData"]>>;
  currentRoleData: IRole;
  setCurrentRoleData: React.Dispatch<React.SetStateAction<IRole>>;
  currentCompanyData: ICompany;
  setCurrentCompanyData: React.Dispatch<React.SetStateAction<ICompany>>;
}
const AuthContext = createContext<IAuthProvider>(undefined);

export function AuthProvider({children}: any) {
  const userId = localStorage.getItem("userId");
  const companyId = localStorage.getItem("companyId");
  const role = localStorage.getItem("role");

  const [userData, setUserData] = useState<IAuthProvider["userData"]>();
  const [currentRoleData, setCurrentRoleData] = useState<IRole>(
    userData?.roles?.find((i) => i.role === role)
  );
  const [currentCompanyData, setCurrentCompanyData] = useState<ICompany>();

  return (
    <AuthContext.Provider
      value={{
        userId,
        companyId,
        role,
        setUserData,
        userData,
        currentRoleData,
        setCurrentRoleData,
        currentCompanyData,
        setCurrentCompanyData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
