import Button from "@mui/material/Button";
import * as S from "./styles";
import {useEffect, useState} from "react";
import {
  collection,
  collectionGroup,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import {db} from "../../firebase";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../contexts/Auth";
import {Header} from "../../components/Header";

function Companies() {
  const {setCurrentCompanyData, userData, setUserData, setCurrentRoleData} =
    useAuth();
  const navigate = useNavigate();

  async function selectCompany(company: any) {
    let newUserData = userData;
    localStorage.setItem("companyId", company.id);
    const userRoles = doc(
      db,
      "companies",
      company.id,
      "companyUsers",
      userData.uid
    );

    const userDataRoles = (await getDoc(userRoles)).data();
    newUserData = {...newUserData, ...userDataRoles};
    if (newUserData?.roles?.length === 1) {
      localStorage.setItem("role", newUserData.roles[0].role);
      setCurrentRoleData(newUserData.roles[0]);
    }
    setCurrentCompanyData(company);
    setUserData(newUserData);
    navigate("/");
  }

  return (
    <S.Container>
      <Header />
      <S.RolesContainer>
        <S.Title>Select Company</S.Title>
        {userData?.companies?.map((company, i) => (
          <Button key={i} onClick={() => selectCompany(company)}>
            {company.name}
          </Button>
        ))}
      </S.RolesContainer>
    </S.Container>
  );
}

export default Companies;
