import {getAuth} from "firebase/auth";
import {Outlet, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import {db} from "../firebase";
import {IAuthProvider, useAuth} from "./Auth";
import {enqueueSnackbar} from "notistack";
import {exists} from "../utils/verify";

export const AuthRedirect: React.FC<any> = ({children}) => {
  const auth = getAuth();
  const {setUserData, setCurrentCompanyData, setCurrentRoleData} = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        localStorage.setItem("userId", user.uid);
        let newUserData: IAuthProvider["userData"] = {
          uid: user.uid,
          companies: [],
          name: "",
          roles: [],
        };
        const qUserCompanies = query(
          collection(db, "companyUsers"),
          where(documentId(), "==", user.uid)
        );
        await getDocs(qUserCompanies)
          .then(async (data) => {
            const companyIds = data.docs[0].data().companyIds;

            const qCompaniesData = query(
              collection(db, "companies"),
              where(documentId(), "in", companyIds)
            );

            await getDocs(qCompaniesData).then(async (companiesData) => {
              companiesData.forEach((companySnap) => {
                const companyData = companySnap.data();
                newUserData.companies.push({
                  ...companyData,
                  name: companyData.name,
                  id: companySnap.id,
                });
              });

              const role = localStorage.getItem("role");
              const companyId = localStorage.getItem("companyId");
              if (exists(companyId)) {
                const currentCompany = newUserData.companies.find(
                  (comp) => comp.id == companyId
                );
                setCurrentCompanyData(currentCompany);
                const userRoles = doc(
                  db,
                  "companies",
                  companyId,
                  "companyUsers",
                  user.uid
                );

                const userDataRoles = (await getDoc(userRoles)).data();
                newUserData = {...newUserData, ...userDataRoles};
                if (exists(role)) {
                  const currentRole = newUserData.roles.find(
                    (rol) => rol.role === role
                  );
                  setCurrentRoleData(currentRole);
                }

                if (newUserData?.roles?.length === 1) {
                  localStorage.setItem("role", newUserData.roles[0].role);
                  setCurrentRoleData(newUserData.roles[0]);
                }
              }

              if (newUserData?.companies?.length === 1) {
                localStorage.setItem("companyId", newUserData.companies[0].id);
                setCurrentCompanyData(newUserData.companies[0]);
              }
              setUserData(newUserData);
            });
          })
          .then(() => {
            navigate("/");
          });
      } else {
        localStorage.removeItem("userId");
        localStorage.removeItem("companyId");
        navigate("/login");
      }
    });
  }, []);

  return children ? <div>{children}</div> : <Outlet />;
};
