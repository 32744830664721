import Button from "@mui/material/Button";
import * as S from "./styles";
import {useEffect, useState, useLayoutEffect} from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import {db} from "../../firebase";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../contexts/Auth";
import {Header} from "../../components/Header";
import {exists} from "../../utils/verify";

function Roles() {
  const {userData, setUserData, setCurrentRoleData, companyId} = useAuth();
  const navigate = useNavigate();

  function selectRole(role: any) {
    localStorage.setItem("role", role.role);
    setCurrentRoleData(role);
    navigate("/");
  }

  useEffect(() => {
    (async () => {
      if (!exists(userData?.roles) && exists(userData?.uid)) {
        const userRoles = doc(
          db,
          "companies",
          companyId,
          "companyUsers",
          userData?.uid
        );

        const userDataRoles = (await getDoc(userRoles)).data();

        setUserData((prevValue) => ({
          ...prevValue,
          ...userDataRoles,
        }));
      }
    })();
  }, []);

  return (
    <S.Container>
      <Header />
      <S.RolesContainer>
        <S.Title>Select Role</S.Title>
        {userData?.roles?.map((role, i) => (
          <Button key={i} onClick={() => selectRole(role)}>
            {role.role}
          </Button>
        ))}
      </S.RolesContainer>
    </S.Container>
  );
}

export default Roles;
