import styled from "styled-components";

export const DrawerContainer = styled.div`
  width: 250px;
  height: 100%;
  background-color: #222;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 22px;
  padding-bottom: 12px;
`;

export const DrawerContainerList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const KovigoText = styled.p`
  font-size: 14px;
  width: 100%;
  text-align: center;
  color: #9c9c9c;
  font-weight: 300;
`;

export const TitleSection = styled.p`
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 600;
  color: #000;
  line-height: 20px;
`;

export const DataText = styled.p`
  margin-bottom: 12px;
  font-size: 13px;
  font-weight: 400;
  color: #757575;
  line-height: 13px;
`;

export const ProductsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  gap: 12px;
`;

export const ProductContainer = styled.div`
  display: grid;
  box-shadow: 0 2px 4px #0003;
  border-radius: 6px;
  grid-template-columns: 1fr 100px;
  grid-template-rows: 20px 1fr;
  row-gap: 8px;
  column-gap: 8px;
  padding: 12px;
`;

export const ProductTitle = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
`;
export const ProductDescription = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  color: #757575;
  align-self: self-end;
`;

export const ProductQtdText = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #757575;
  line-height: 16px;
  justify-self: self-end;
`;
