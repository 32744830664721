import styled from "styled-components";

export const Container = styled.header`
  width: 100%;
  display: flex;
  padding: 0 12px;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 1;
  height: 62px;
  background-color: #fead36;
`;

export const Title = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #000;
`;

export const DataContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
