import {Navigate, Outlet} from "react-router-dom";
import {exists} from "../utils/verify";

export const CompanyRedirect: React.FC<any> = ({children}) => {
  const companyId = localStorage.getItem("companyId");

  if (!exists(companyId)) {
    return <Navigate to="/companies" replace />;
  }

  return children ? <div>{children}</div> : <Outlet />;
};
