import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RolesContainer = styled.div`
  width: 100%;
  max-width: 500px;
  padding: 22px;
  margin: 22px;
  display: flex;
  flex-direction: column;
  gap: 22px;
  border-radius: 8px;
  background-color: #fead36;
`;

export const Title = styled.p`
  font-size: 22px;
  color: #000;
  font-weight: 600;
`;
